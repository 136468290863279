<template>
    <div id="mobilenavwrapper">
        <div class="nav-icon-wrapper">
            <!-- ======= These links for www.offshoresms.com.au ========================================================================================== -->
            <!-- <span class="button is-danger header-btn hide550" @click="toLog()">View Log</span> -->
            <span class="button is-light header-btn hide550" @click="trackLink('sign-in', 'login')">Log in</span>
            <span class="button is-success header-btn last hide550" @click="trackLink('sign-up', 'signup')">Register</span>

            <!-- ======= These links for www.dash.offshoresms.com.au ========================================================================================== -->
            <!-- <router-link class="button is-light header-btn hide550" to="sign-in">Log in</router-link>
      <router-link class="button is-success header-btn last hide550" to="sign-up">Register</router-link> -->

            <a class="nav-icon-link" href="tel:+61-447-139-382"><font-awesome-icon icon="phone"/></a>

            <!-- <router-link v-if="user" class="nav-icon-link" to="my-vessels"><font-awesome-icon icon="ship" /></router-link> -->

            <!-- <router-link data-v-step="1" class="nav-icon-link" :class="{offline:!onlineStatus}" to="my-dashboard" v-if="user">
        <font-awesome-icon icon="home" />
        <button v-show="!onlineStatus" class="delete is-small"></button>
      </router-link> -->
        </div>

        <div id="nav-icon" ref="nav-icon" v-bind:class="{ active: isActive }" v-on:click="toggle">
            <span></span>
            <span></span>
            <span></span>
        </div>

        <div v-if="seen" class="dropmenu" v-closable="{ exclude: ['nav-icon'], handler: 'closeModal' }">
            <ul>
                <!-- <router-link to="sign-in-custom" class="button is-primary">Custom Sign-in</router-link> -->
                <li>
                    <router-link to="/"><font-awesome-icon icon="home" /> &nbsp;Home Page</router-link>
                </li>
                <!-- <li><router-link to="admin-vessels" v-if="user">All Vessels</router-link></li> -->
                <li>
                    <a href="https://dash.offshoresms.com.au/"><font-awesome-icon icon="briefcase" /> &nbsp;My Dashboard</a>
                </li>
                <!-- <li v-if="!user">
          <a href="https://dash.offshoresms.com.au/sign-in"><font-awesome-icon icon="users" /> &nbsp;Login</a>
        </li> -->
                <li v-if="!user">
                    <a href="https://dash.offshoresms.com.au/"><font-awesome-icon icon="users" /> &nbsp;Create a Profile</a>
                </li>
                <!-- <li>
          <router-link to="my-companies" v-if="user">My Vessel Fleets</router-link>
        </li> -->
                <!-- <li><router-link to="company-profile" v-if="user && isUserAdmin">Company Profile</router-link></li>
              <li><router-link to="my-profile" v-if="user && !isUserAdmin">My Profile</router-link></li> -->
                <!-- <li><router-link to="getting-started" v-if="user">Getting Started</router-link></li> -->
                <li v-if="!user">
                    <router-link to="pricing"><font-awesome-icon icon="money-bill-alt" /> &nbsp;View Pricing</router-link>
                </li>
                <!-- <li><router-link to="sign-up" v-if="!user">Create an account</router-link></li> -->
                <!-- <li><a @click.prevent="showSignUpModal = true" v-if="!user">Create an account</a></li> -->
                <!-- <li><a @click.prevent="toSignUp" v-if="!user">Create a Free Profile</a></li> -->
                <!-- <li><router-link to="faqs">Frequent Questions</router-link></li> -->
                <!-- <li><router-link to="how-it-works">How it Works</router-link></li> -->
                <!-- <li><router-link to="feedback">Get in Touch</router-link></li> -->
                <!-- <li>
          <router-link to="explore-features">Explore Features</router-link>
        </li> -->

                <!-- <li>
          <router-link to="user-guide-start">All User Guides</router-link>
        </li> -->

                <li>
                    <router-link to="useful-links"><font-awesome-icon icon="info-circle" /> &nbsp;Useful Links</router-link>
                </li>

                <li>
                    <router-link to="feedback"><font-awesome-icon icon="life-ring" /> &nbsp;Contact Us</router-link>
                </li>

                <!-- <li>
          <router-link to="new-vessel-info"><font-awesome-icon icon="ship" /> &nbsp;New Vessel Info</router-link>
        </li> -->

                <!-- <li>
          <router-link to="support"><font-awesome-icon icon="life-ring" /> &nbsp;Help &amp; Support</router-link>
        </li> -->

                <!-- <li>
          <router-link to="resources-home"><font-awesome-icon icon="info-circle" /> &nbsp;Resources</router-link>
        </li> -->

                <!-- <li>
          <router-link to="sign-in" v-if="!user">Sign-in</router-link>
        </li> -->
                <li><a v-on:click="signOut" v-if="user">Sign-out</a></li>
            </ul>
        </div>
        <div class="clear"></div>

        <!-- <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showSignUpModal}">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Join Offshore SMS</p>
        </header>
        <section class="modal-card-body">
          <div class="buttons has-text-centered">
            <a class="button is-primary" @click.prevent="toSmsSignUp">Get an SMS from $249/year</a>
            <a class="button is-info" @click.prevent="toSignUp">Or join for Free as a Crew</a>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" @click="close">Close</button>
        </footer>
      </div>
    </div> -->
    </div>
</template>
<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
// import 'firebase/firestore';
// import 'firebase/functions';
//import { store } from '../store/store';
import { db } from '../main.js';
import NProgress from 'nprogress';

export default {
    computed: {
        user() {
            return this.$store.getters.getUser;
        }
        // isUserAdmin() {
        //   return this.$store.getters.getIsUserAdmin;
        // },
        // adminStatus() {
        //   return this.isUserAdmin ? 'Admin' : 'Crew'
        // },
        // getUserName() {
        //   let user = this.$store.getters.getUser;
        //   console.log(user);
        //   if (user) {
        //     if (user.displayName) {
        //       let firstName = user.displayName.split(" ");
        //       return 'Hi ' + firstName[0];
        //     } else {
        //       return 'Hi';
        //     }
        //   } else {
        //     return '';
        //   }

        // },
        // statusMsg() {
        //   let status = this.$store.getters.getIsUserAdmin;
        //   const statusMsg = (status ? "You are an Admin" : "You are crew");
        //   return statusMsg;
        // },
        // isRunning() {
        //   return this.$store.getters.getIsTimerRunning;
        // },
        // isOpen() {
        //   return this.$store.getters.getIsTimerOpen;
        // },
        // userTest() {
        //   return this.$store.getters.getUserTestData;
        // },
        // userTestName() {
        //   return this.userTest ? this.userTest.slice(0, 7) : '!!!'
        // },
        // myCompanies() {
        //   return this.$store.getters.getMyCompanies;
        // }
    },

    data: function() {
        return {
            isActive: true,
            seen: false,
            onlineStatus: false,
            browser: '',
            showSignUpModal: false,
            firebaseid: '',
            firebasedisplayname: '',
            hasData: false,
            //userProfile: [],
            myUsername: ''
        };
    },

    mounted() {
        //var self = this;
        //console.log(navigator.onLine);
        //window.addEventListener('load', () => {
        //console.log(navigator.onLine);
        // 1st, we set the correct status when the page loads
        //navigator.onLine ? this.showStatus(true) : this.showStatus(false);
        navigator.onLine ? (this.onlineStatus = true) : (this.onlineStatus = false);

        // now we listen for network status changes
        window.addEventListener('online', () => {
            //this.showStatus(true);
            //console.log('Online xx');
            this.onlineStatus = true;
        });

        window.addEventListener('offline', () => {
            //this.showStatus(false);
            //console.log('Offline xx');
            this.onlineStatus = false;
        });

        this.browser = this.$browserDetect.meta.name;
        this.$store.dispatch('SETONLINESTATUS');
        //this.$store.dispatch('SETDATASTATUS');
        // this.$store.dispatch('GETISRUNNING');
        // this.$store.dispatch('GETISTIMEROPEN');
        // this.$store.dispatch('GETTIMERSTART');
        // this.$store.dispatch('GETTIMERSTOP');
        // this.$store.dispatch('GETTIMERTOTAL');

        // this.checkData();
        //this.$store.dispatch('SETUSERPROFILE');

        //});
    },

    watch: {
        $route(to, from) {
            this.isActive = false;
            this.seen = false;
        }
    },
    methods: {
        // checkData: function() {
        //   let self = this;
        //   if (self.user) {
        //     db.collection('userdata').doc(self.user.uid).get().then(function(doc) {
        //       // self.myUsername = doc.data().username ? doc.data().username.slice(0,7) : '!!!'
        //       self.myUsername = doc.data().username ? '' : '!!!'
        //     });
        //   }
        // },

        trackLink(page, type) {
            console.log(page, type);
            NProgress.start();
            let addRevision = firebase.functions().httpsCallable('addRevision');
            addRevision({
                userID: '-',
                userName: '-',
                description: 'A user has clicked through to the app.offshoresms ' + type + ' page.'
            });
            // this.$router.replace(page);
            // window.location.href = 'https://dash.offshoresms.com.au/' + page;
            window.location.href = 'https://dash.offshoresms.com.au/';
            NProgress.done();
        },

        toLog() {
            this.$router.push({
                name: 'CustomLogResponse',
                params: {
                    showBack: true
                },
                query: { vid: 'BNltco7v26kkdTbhFxGh', lid: 'B6mfNOh4kqXj32HN827H' }
            });
        },

        signOut: function() {
            var self = this;
            var addRevision = firebase.functions().httpsCallable('addRevision');
            addRevision({
                userID: self.user.uid,
                userName: self.user.displayName,
                description: 'A user has signed out.'
            });

            var userHasEngaged = firebase.functions().httpsCallable('userHasEngaged');
            userHasEngaged({
                userID: self.user.uid,
                userName: self.user.displayName,
                message: 'A user has signed out of Offshore SMS.',
                subject: 'A user has signed out'
            });

            firebase
                .auth()
                .signOut()
                .then(() => {
                    //console.log("signed out.");

                    this.$store.dispatch('resetStates');
                    // this.$router.replace('/');
                    this.$router.push({
                        name: 'new-home'
                    });
                    // this.$router.push({
                    //   name: 'signIn',
                    //   params: {
                    //     reload: true,
                    //   }
                    // });
                });
        },

        // toTimer: function() {
        //   this.$router.push({
        //     name: 'sea-time',
        //     params: {
        //       loadTab: 'seatime'
        //     }
        //   });
        // },

        // close: function() {
        //   this.showSignUpModal = false;
        // },

        // toSmsSignUp: function() {
        //   this.showSignUpModal = false;
        //   this.$router.push({ name: 'smsSignup' });
        // },

        // toSignUp: function() {
        //   this.showSignUpModal = false;
        //   this.$router.push({
        //     name: 'signUp',
        //     params: {
        //       planType: 'free',
        //       maxVessels: 0
        //     }
        //   });
        // },

        toggle: function(event) {
            this.isActive = !this.isActive;
            this.seen = !this.seen;
        },

        closeModal: function() {
            this.isActive = !this.isActive;
            this.seen = !this.seen;
        }
    }
};
</script>
<style lang="scss">
@import '././node_modules/bulma/sass/utilities/_all.sass';

#mobilenavwrapper {
    margin-right: 45px;
    float: right;
    font-size: 25px;
    margin-top: 11px;

    .nav-icon-link {
        display: table-cell;
        display: inline-block;
        vertical-align: super;
        padding-right: 20px;
        color: #245e8b;
        &.text {
            font-size: 1rem;
            display: inline-block;
            margin-top: 5px;
            vertical-align: super;
        }
        img {
            height: 28px;
            margin: 0;
        }
    }

    .user-status {
        position: absolute;
        right: 118px;
        top: 19px;
        font-size: 16px;
        color: #fbc51b;
        // @include mobile {
        //   display: none;
        // }
    }
}

.menu-fish {
    position: absolute;
    top: 12px;
    right: 120px;
    cursor: pointer;
    font-size: 23px;
    color: #245e8b;
    transition: 0.3s;

    &:hover {
        color: #1c99ff;
    }
}

.menu-user {
    position: absolute;
    top: 12px;
    right: 70px;
    cursor: pointer;
    font-size: 23px;
    color: #245e8b;
    transition: 0.3s;

    &:hover {
        color: #1c99ff;
    }

    .delete {
        position: absolute;
        bottom: -3px;
        right: -8px;
        background: #f95b7a;

        &:hover {
            background-color: #f95b7a;
        }
    }
}

// .menu-facebook {
//   position: absolute;
//   top: 11px;
//   right: 115px;
//   cursor: pointer;
//   font-size: 23px;
//   color: #245e8b;
//   transition: 0.3s;

//   &.noUser {
//     right: 80px;
//   }

//   &:hover {
//     color: #1c99ff;
//   }
// }

// .menu-timer-icon {
//   position: absolute;
//   top: 17px;
//   right: 92px;
//   cursor: pointer;
//   transition: 0.3s;

//   img {
//     width: 20px;
//     height: 20px !important;
//     float: none;
//     margin: 0;
//   }
// }

// .menu-open-icon {
//   position: absolute;
//   top: 20px;
//   right: 140px;
//   cursor: pointer;
//   transition: 0.3s;
//   color: red;

//   &.open {
//     color: green;
//   }
// }

.button.header-btn {
    margin-right: 10px;
    // height: auto;
    height: 36px;
    font-size: 1rem;
    padding: 8px 15px;
    &.last {
        margin-right: 30px;
    }
}

@media only screen and (max-width: 550px) {
    #mobilenavwrapper {
        .hide550 {
            display: none;
        }
    }
}

@media only screen and (max-width: 479px) {
    #mobilenavwrapper {
        margin-right: 30px;
        margin-top: 14px;
        padding-right: 15px;
        .nav-icon-link {
            font-size: 21px;
            padding-right: 12px;
            img {
                height: 24px;
                margin-top: 3px;
            }
            &:last-of-type {
                padding-right: 18px;
            }
            &.hide479 {
                display: none;
            }
        }
    }
}
</style>
